<template>
  <base-modal-side
    :is-dimmed="true"
    :auto-hide="true"
    @closeModal="actions.closeModal()"
  >
    <template #modalHeader>
      <h3 class="modal-title">다음달 정산 예정 금액</h3>
    </template>

    <template #modalBody>
      <div
        class="prev-calculate"
        :class="{ 'exist-both__cur': state.existBothCurrency }"
      >
        <div class="text">
          <p class="sub-text-s2 text-gray-reverse">정산 예정 금액</p>

          <div class="tooltip-wrapper">
            <tooltip
              container=".side-modal"
              :triggers="['hover']"
              placement="top"
            >
              <template #button>
                <span
                  class="calculate-tooltip-btn e-title-1 text-gray-reverse"
                  :class="{ available: state.settleAble }"
                >
                  {{ state.settleAbleTooltipText }}
                  <help-icon
                    class="help-icon"
                    width="12"
                    height="12"
                    fill-color="#ffffff"
                  ></help-icon>
                </span>
              </template>
              <template #tooltip>
                <p
                  class="tooltip-content c-text text-gray-second"
                  v-html="state.settleAbleTooltipMessage"
                ></p>
              </template>
            </tooltip>
          </div>
        </div>

        <div v-if="state.existKRW" class="expected-price">
          <span
            v-if="state.existBothCurrency"
            class="sub-text-s2 text-gray-reverse"
          >
            원화
          </span>
          <h1 class="text-gray-reverse">{{ state.expectedAmount["KRW"] }}</h1>
        </div>

        <div v-if="state.existUSD" class="expected-price">
          <span
            v-if="state.existBothCurrency"
            class="sub-text-s2 text-gray-reverse"
          >
            달러
          </span>
          <h1 class="text-gray-reverse">{{ state.expectedAmount["USD"] }}</h1>
        </div>
      </div>

      <div class="content-header">
        <h5>{{ state.nextMonth }}월 정산 내역</h5>
      </div>

      <ul class="calculate-list">
        <li class="sales">
          <span class="sub-text-s2 text-gray-second">판매 금액</span>
          <span class="price sub-title-s2">{{ state.salesAmount }}</span>
        </li>
        <li>
          <span class="sub-text-s2 text-gray-second">부가세</span>
          <span class="price sub-title-s2">{{ state.VAT }}</span>
        </li>
        <li>
          <span class="sub-text-s2 text-gray-second">PG 수수료</span>
          <span class="price sub-title-s2">{{ state.pgFee }}</span>
        </li>
        <li>
          <span class="sub-text-s2 text-gray-second">서비스 이용 수수료</span>
          <span class="price sub-title-s2">{{ state.serviceFee }}</span>
        </li>
      </ul>

      <div class="notice-list-wrapper">
        <ul class="notice-list c-text text-gray-second">
          <li>
            정산 예정 금액은 판매 금액에서 각종 수수료가 빠진 정산 금액입니다.
          </li>
          <li>
            매월 1일에 정산 금액이 확정됩니다. 이번 달 정산 금액은 매월 1일에
            초기화되며, 상세 내용은 정산 내역에서 확인해 주세요.
          </li>
          <li>정산 예정 금액이 10만원 이하인 경우 정산되지 않고 이월됩니다.</li>
          <li>
            달러로 판매된 금액은 위 내용에 포함되지 않으며, 정산 확정일에 정산
            내역에서 확인하실 수 있습니다.
          </li>
          <li>
            다음의 판매 건은 정산 예정 금액에 포함되지 않습니다.
            <ul class="sub-list c-text text-gray-second">
              <li>&bull;환불된 판매 건</li>
              <li>&bull;라이브가 진행되지 않은 판매 건</li>
              <li>
                &bull;환불 기간이 남은 판매 건
                <a @click="actions.goToRefundNotice()">환불정책 보기</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </template>
    <template #modalFooter>
      <button class="modal-footer-btn" @click="actions.closeModal()">
        확인
      </button>
    </template>
  </base-modal-side>
</template>
<script>
import BaseModalSide from "../../../../components/common/BaseModalSide";
import Tooltip from "../../../../components/console/tooltip/Tooltip";
import { reactive, computed } from "vue";
import HelpIcon from "../../../../components/console/icons/HelpIcon";
import helper from "@/helper";
import { useStore } from "vuex";
import { settlementPriceFormat } from "@/helper/settlement";
import moment from "moment-timezone";

export default {
  name: "CalculateModal",
  components: { HelpIcon, Tooltip, BaseModalSide },
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      summary: computed(() => {
        return store.getters["settlements/settlementSummary"];
      }),
      settleAble: computed(() => {
        const krwAmount = state.summary.expectedSettlementAmount["KRW"]
          ? state.summary.expectedSettlementAmount["KRW"].amount
          : 0;

        const usdAmount = state.summary.expectedSettlementAmount["USD"]
          ? state.summary.expectedSettlementAmount["USD"].amount
          : 0;

        const totalAmount = usdAmount * 1200 + krwAmount;

        return totalAmount > 100000; // 10만원 이상일 경우
      }),
      existKRW: computed(() => {
        return !!state.summary.expectedSettlementAmount["KRW"];
      }),
      existUSD: computed(() => {
        return !!state.summary.expectedSettlementAmount["USD"];
      }),
      existBothCurrency: computed(() => {
        return state.existKRW && state.existUSD;
      }),
      expectedAmount: computed(() => {
        let result = {
          KRW: null,
          USD: null,
        };
        if (state.existKRW) {
          result.KRW = settlementPriceFormat(
            state.summary.expectedSettlementAmount["KRW"].currency,
            state.summary.expectedSettlementAmount["KRW"].expectedAmount
          );
        }
        if (state.existUSD) {
          result.USD = settlementPriceFormat(
            state.summary.expectedSettlementAmount["USD"].currency,
            state.summary.expectedSettlementAmount["USD"].expectedAmount
          );
        }
        return result;
      }),
      settleAbleTooltipText: computed(() => {
        return state.settleAble ? "예정" : "이월";
      }),
      settleAbleTooltipMessage: computed(() => {
        return state.settleAble
          ? "정산 예정 금액은 판매 금액에서<br/>각종 수수료를 뺀 정산 금액이에요"
          : "정산 예정 금액이 10만원 이하면<br/>정산 금액이 다음 달로 이월돼요";
      }),
      nextMonth: moment().add("months", 1).format("M"),
      salesAmount: computed(() => {
        if (state.existKRW) {
          return settlementPriceFormat(
            state.summary.expectedSettlementAmount["KRW"].currency,
            state.summary.expectedSettlementAmount["KRW"].amount
          );
        } else {
          return settlementPriceFormat(
            state.summary.expectedSettlementAmount["KRW"].currency,
            0
          );
        }
      }),
      VAT: computed(() => {
        if (state.existKRW) {
          return `-${settlementPriceFormat(
            state.summary.expectedSettlementAmount["KRW"].currency,
            state.summary.expectedSettlementAmount["KRW"].VAT
          )}`;
        } else {
          return settlementPriceFormat(
            state.summary.expectedSettlementAmount["KRW"].currency,
            0
          );
        }
      }),
      pgFee: computed(() => {
        if (state.existKRW) {
          return `-${settlementPriceFormat(
            state.summary.expectedSettlementAmount["KRW"].currency,
            state.summary.expectedSettlementAmount["KRW"].pgFee
          )}`;
        } else {
          return settlementPriceFormat(
            state.summary.expectedSettlementAmount["KRW"].currency,
            0
          );
        }
      }),
      serviceFee: computed(() => {
        if (state.existKRW) {
          return `-${settlementPriceFormat(
            state.summary.expectedSettlementAmount["KRW"].currency,
            state.summary.expectedSettlementAmount["KRW"].serviceFee
          )}`;
        } else {
          return settlementPriceFormat(
            state.summary.expectedSettlementAmount["KRW"].currency,
            0
          );
        }
      }),
    });

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      goToRefundNotice: () => {
        helper.goToExternalUrl(
          "https://bigc-im.notion.site/2fd1b01e888c4bcab5d4f133a026da83#dee1b3ba5f2947058b0cf6f0a545b6ed"
        );
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
