<template>
  <div>
    <p class="b-text-2 text-gray-second">{{ state.day }}</p>
    <ul class="income-list">
      <li
        v-for="(inOutItem, index) in bankbookDayHistory"
        :key="`inOutMoney-${index}`"
      >
        <bankbook-in-out-item :in-out-item="inOutItem"></bankbook-in-out-item>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import moment from "moment-timezone";

import BankbookInOutItem from "../BankbookInOutItem/BankbookInOutItem";

export default {
  name: "BankbookDate",
  components: { BankbookInOutItem },
  props: {
    bankbookDayHistory: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      day: computed(() => {
        return moment(props.bankbookDayHistory[0].createdAt)
          .locale("ko")
          .format("YYYY.MM.DD dddd");
      }),
    });

    return { state };
  },
};
</script>

<style src="./style.css" scoped></style>
