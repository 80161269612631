
import Tooltip from "@/components/console/tooltip/Tooltip.vue";
import HelpIcon from "@/components/console/icons/HelpIcon.vue";
import { PropType } from "vue";

export default {
  name: "HelpTooltip",
  components: { HelpIcon, Tooltip },
  props: {
    iconColor: {
      type: String as PropType<string>,
      default: "#818287",
    },
    tooltipText: {
      type: String as PropType<string>,
      default: "",
    },
    container: {
      type: String as PropType<string>,
      default: ".console",
    },
    placement: {
      type: String,
      default: "top",
      validator(value) {
        return [
          "auto",
          "auto-start",
          "auto-end",
          "top",
          "top-start",
          "top-end",
          "right",
          "right-start",
          "right-end",
          "bottom",
          "bottom-start",
          "bottom-end",
          "left",
          "left-start",
          "left-end",
        ].includes(value);
      },
    },
  },
};
