<template>
  <dropdown
    :triggers="['click']"
    :delay="0"
    :distance="distance"
    :auto-hide="true"
    :placement="placement"
    :container="container"
    :disabled="disabled"
    :skidding="skidding"
  >
    <slot name="button"></slot>

    <template #popper>
      <slot name="dropdownList"></slot>
    </template>
  </dropdown>
</template>

<script>
import { reactive } from "vue";
import { Dropdown } from "floating-vue";

export default {
  name: "ButtonDropdown",
  components: {
    Dropdown,
  },
  props: {
    distance: {
      type: String,
      default: "-6",
    },
    placement: {
      type: String,
      default: "bottom-end",
      validator(value) {
        return [
          "auto",
          "auto-start",
          "auto-end",
          "top",
          "top-start",
          "right",
          "right-start",
          "right-end",
          "bottom",
          "bottom-start",
          "bottom-end",
          "left",
          "left-start",
          "left-end",
        ].includes(value);
      },
    },
    //note 툴팁 엘리먼트의 상위 엘리먼트를 설정하는 값, 툴팁의 레이아웃 문제가 생기면 이 값을 조절해서 수정가능 ( ex) z-index 문제 )
    container: {
      type: String,
      default: ".console",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    //note 화살표 기준으로 좌우 위치 조정 가능
    skidding: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const state = reactive({});

    return { state };
  },
};
</script>

<style scoped>
.dropdown-toggle::after {
  display: none;
}
.v-popper__arrow-container {
  display: none !important;
}
</style>
