<template>
  <icon-base
    icon-name="help-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 13C10.7614 13 13 10.7614 13 8C13 5.23858 10.7614 3 8 3C5.23858 3 3 5.23858 3 8C3 10.7614 5.23858 13 8 13ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
      :fill="fillColor"
    />
    <circle cx="7.85645" cy="11" r="0.5" :fill="fillColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.8566 6C7.60016 6 7.36731 6.09587 7.19006 6.25451C7.0907 6.34344 7.0094 6.45167 6.95203 6.57285C6.83386 6.82244 6.53574 6.92897 6.28616 6.8108C6.03658 6.69264 5.93004 6.39452 6.04821 6.14494C6.16329 5.90188 6.32563 5.68616 6.52314 5.50938C6.87665 5.19298 7.34468 5 7.8566 5C8.96117 5 9.8566 5.89543 9.8566 7C9.8566 7.93193 9.2192 8.71498 8.35658 8.937L8.35656 9.50002C8.35655 9.77616 8.13269 10 7.85654 10C7.5804 9.99999 7.35655 9.77612 7.35656 9.49998L7.3566 8.49998C7.35661 8.22385 7.58046 8 7.8566 8C8.40888 8 8.8566 7.55228 8.8566 7C8.8566 6.44772 8.40888 6 7.8566 6Z"
      :fill="fillColor"
    />
  </icon-base>
</template>
<script>
import IconBase from "../../common/IconBase";
export default {
  name: "HelpIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
  },
};
</script>
