<template>
  <div class="page-container-new">
    <page-header
      title="수익정산"
      description="판매 중인 프리미엄 콘텐츠를 관리하고 구매한 멤버와 데이터 분석까지 확인해보세요."
      guide-link="https://www.notion.so/bigc-im/SNB-T-D-80e3899ab2de44df9ecd94dabb141cb2"
    ></page-header>

    <div class="container-body-overflow">
      <div class="bank-section">
        <card-basic v-if="!state.showBankInfo" class="bank-dimmer">
          <div ref="bankDimmer" class="inner">
            <div class="animation-images">
              <img
                class="ani-coin"
                src="/assets/images/settlement/ani_coin.png"
              />
              <img class="pig-img" src="/assets/images/settlement/pig.png" />
            </div>
            <p class="sub-text-s2 text-gray-second">
              두근두근! <br />빅크 통장에 얼마가 쌓여 있을까요?
            </p>
            <button class="sub-text-s2" @click="actions.closeBankInfoDimmer()">
              통장 확인하기 <arrow-icon stroke-size="normal"></arrow-icon>
            </button>
          </div>
        </card-basic>

        <card-basic class="bank-card">
          <div ref="bankCard" class="total-info">
            <div
              class="txt-info"
              :class="{
                'exist-one__currency': !state.existBothNonSettlementSalesAmount,
              }"
            >
              <div class="header" @click="actions.openBankBook()">
                <h4>빅크 통장</h4>
                <arrow-icon
                  width="18"
                  height="18"
                  stroke-size="normal"
                ></arrow-icon>
              </div>

              <p class="b-text-3 text-gray-second">
                통장에 쌓인 판매 금액과 빅크 포인트를 확인해 보세요!
              </p>

              <template v-if="!state.existBothNonSettlementSalesAmount">
                <div class="total-amount">
                  <h1>
                    {{
                      state.nonSettlementSalesAmount.KRW
                        ? state.nonSettlementKRWTotal
                        : state.nonSettlementSalesAmount.USD
                    }}
                  </h1>
                </div>

                <ul
                  v-if="state.existKRWNonSettlementSalesAmount"
                  class="amount-item-list"
                >
                  <li>
                    <span class="b-text-2 text-gray-second">
                      판매 금액(VAT 포함)
                    </span>
                    <span class="sub-title-s2 text-secondary">
                      {{ state.nonSettlementKRWTotal }}
                    </span>
                  </li>
                  <li>
                    <div class="tooltip-btn">
                      <span class="b-text-2 text-gray-second">
                        보유중인 빅크 포인트
                        <help-tooltip
                          :tooltip-text="`빅크 포인트는 콘텐츠 판매 금액의 10%까지 <br />
                              정산 가능하며, 자동 정산됩니다.`"
                        ></help-tooltip>
                      </span>
                    </div>

                    <span class="sub-title-s2 text-secondary">
                      {{ state.totalPoint }}
                    </span>
                  </li>
                </ul>
              </template>

              <template v-if="state.existBothNonSettlementSalesAmount">
                <div class="total-amount__wrapper">
                  <div class="item">
                    <div class="tit">
                      <span class="sub-text-s1">원화</span>
                      <h1>{{ state.nonSettlementSalesAmount.KRW }}</h1>
                    </div>
                    <ul class="amount-item-list">
                      <li>
                        <span class="b-text-2 text-gray-second"
                          >판매 금액(VAT 포함)</span
                        >
                        <span class="sub-title-s2 text-secondary">
                          {{ state.nonSettlementSalesAmount.KRW }}
                        </span>
                      </li>
                      <li>
                        <div class="tooltip-btn">
                          <span class="b-text-2 text-gray-second">
                            보유중인 빅크 포인트
                            <help-tooltip
                              :tooltip-text="`빅크 포인트는 콘텐츠 판매 금액의 10%까지<br />정산 가능하며, 자동 정산됩니다.`"
                            ></help-tooltip>
                          </span>
                        </div>

                        <span class="sub-title-s2 text-secondary">
                          {{ state.totalPoint }}
                        </span>
                      </li>
                    </ul>
                  </div>

                  <text-divider
                    v-if="state.existBothCurrencySalesTotal"
                    class="item-divider"
                    height="116px"
                    margin="0 20px"
                    color="#E6EAEF"
                  ></text-divider>

                  <div v-if="state.existUSDTotalSalesAmount" class="item">
                    <div class="tit">
                      <span class="sub-text-s1">달러</span>
                      <h1>{{ state.nonSettlementSalesAmount.USD }}</h1>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div class="bank-img-wrapper">
              <img src="/assets/images/settlement/coin_pig.png" />
            </div>
          </div>

          <div class="info-list-wrapper">
            <ul>
              <li>
                <div class="info-header" @click="actions.openCalculateModal()">
                  <span class="tit sub-text-s3 text-gray-second">
                    다음달 정산 예정 금액
                  </span>
                  <arrow-icon
                    width="16"
                    height="16"
                    stroke-size="normal"
                  ></arrow-icon>
                </div>
                <p class="desc c-text text-gray-second">
                  매월 1일에 금액이 확정됩니다.
                </p>
                <div class="price">
                  <h5 v-if="state.existKRWExpectedSettlementAmount">
                    {{ state.expectedSettlementAmount.KRW }}
                  </h5>

                  <text-divider
                    v-if="state.existBothCurrencySettlementAmount"
                    margin="0 8px"
                    color="#E6EAEF"
                  ></text-divider>

                  <h5 v-if="state.existUSDExpectedSettlementAmount">
                    {{ state.expectedSettlementAmount.USD }}
                  </h5>

                  <div class="tooltip-wrapper">
                    <tooltip :triggers="['hover']" placement="top">
                      <template #button>
                        <span
                          class="calculate-tooltip-btn e-title-1 text-gray-reverse"
                          :class="{ available: state.settleAble }"
                        >
                          {{ state.settleAbleTooltipText }}
                          <help-icon fill-color="#ffffff"></help-icon>
                        </span>
                      </template>
                      <template #tooltip>
                        <p
                          class="tooltip-content c-text text-gray-second"
                          v-html="state.settleAbleTooltipMessage"
                        ></p>
                      </template>
                    </tooltip>
                  </div>
                </div>
              </li>
              <li>
                <div class="info-header">
                  <span class="tit sub-text-s3 text-gray-second">
                    최근 실수령 금액
                  </span>
                </div>
                <p class="desc c-text text-gray-second">
                  최근 지급된 실수령 금액입니다.
                </p>
                <div class="price">
                  <h5>{{ state.beforeSettlementPayout }}</h5>
                </div>
              </li>
              <li>
                <div class="info-header">
                  <span class="tit sub-text-s3 text-gray-second">
                    누적 판매 금액
                  </span>
                </div>
                <p class="desc c-text text-gray-second">
                  빅크에서 판매한 누적 금액입니다.
                </p>
                <div class="price">
                  <h5 v-if="state.existKRWTotalSalesAmount">
                    {{ state.totalSalesAmount.KRW }}
                  </h5>

                  <text-divider
                    v-if="state.existBothCurrencySalesTotal"
                    margin="0 8px"
                    color="#E6EAEF"
                  ></text-divider>

                  <h5 v-if="state.existUSDTotalSalesAmount">
                    {{ state.totalSalesAmount.USD }}
                  </h5>
                </div>
              </li>
            </ul>
          </div>
        </card-basic>
      </div>

      <div class="tab-list-wrapper">
        <ul class="tab-list">
          <li>
            <button-tab
              text="판매 내역"
              :active="
                state.activeTab === 'settlement' || state.activeTab === 'sales'
              "
              @action="actions.changeTab('sales')"
            ></button-tab>
          </li>
          <li>
            <button-tab
              text="정산 내역"
              :active="state.activeTab === 'calculate'"
              @action="actions.changeTab('calculate')"
            ></button-tab>
          </li>
          <li>
            <button-tab
              text="빅크 포인트"
              :active="state.activeTab === 'point'"
              @action="actions.changeTab('point')"
            ></button-tab>
          </li>
          <li>
            <button-tab
              text="정산 정보"
              :active="state.activeTab === 'info'"
              @action="actions.changeTab('info')"
            ></button-tab>
          </li>
        </ul>
      </div>

      <router-view></router-view>
    </div>

    <teleport :disabled="true">
      <bank-modal
        v-if="state.showBankModal"
        @hideModal="actions.closeBankModal()"
      ></bank-modal>

      <calculate-modal
        v-if="state.showCalculateModal"
        @hideModal="actions.closeCalculateModal()"
      ></calculate-modal>
    </teleport>
  </div>
</template>

<script>
import PageHeader from "../../../components/console/headers/PageHeader";
import CardBasic from "../../../components/console/cards/CardBasic";
import ArrowIcon from "../../../components/console/icons/ArrowIcon";
import { useRoute, useRouter } from "vue-router";
import { computed, onMounted, reactive, ref } from "vue";
import helper from "@/helper";
import HelpIcon from "../../../components/console/icons/HelpIcon";
import BankModal from "./BankModal/BankModal";
import Tooltip from "../../../components/console/tooltip/Tooltip";
import CalculateModal from "./CalculateModal/CalculateModal";
import ButtonTab from "../../../components/console/buttons/ButtonTab";
import { useStore } from "vuex";
import { settlementPriceFormat } from "@/helper/settlement";
import TextDivider from "@/components/console/dividers/TextDivider.vue";
import HelpTooltip from "@/components/console/tooltip/HelpTooltip.vue";

export default {
  name: "Settlement",
  components: {
    HelpTooltip,
    TextDivider,
    ButtonTab,
    CalculateModal,
    Tooltip,
    BankModal,
    HelpIcon,
    ArrowIcon,
    CardBasic,
    PageHeader,
  },
  setup() {
    const bankDimmer = ref();
    const bankCard = ref();

    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const state = reactive({
      showBankInfo: false,
      summary: computed(() => {
        return store.getters["settlements/settlementSummary"];
      }),
      totalPoint: computed(() => {
        return `${helper.priceFormat(state.summary.totalPoint)}P`;
      }),
      totalSalesAmount: computed(() => {
        let result = {
          KRW: null,
          USD: null,
        };

        if (state.existKRWTotalSalesAmount) {
          result.KRW = settlementPriceFormat(
            state.summary.totalSalesAmount["KRW"].currency,
            state.summary.totalSalesAmount["KRW"].amount +
              state.summary.totalPoint
          );
        }
        if (state.existUSDTotalSalesAmount) {
          result.USD = settlementPriceFormat(
            state.summary.totalSalesAmount["USD"].currency,
            state.summary.totalSalesAmount["USD"].amount
          );
        }
        return result;
      }),
      existUSDTotalSalesAmount: computed(() => {
        return !!state.summary.totalSalesAmount["USD"];
      }),
      existKRWTotalSalesAmount: computed(() => {
        return !!state.summary.totalSalesAmount["KRW"];
      }),
      existBothCurrencySalesTotal: computed(() => {
        return state.existUSDTotalSalesAmount && state.existKRWTotalSalesAmount;
      }),
      beforeSettlementPayout: computed(() => {
        return settlementPriceFormat(
          "KRW",
          state.summary.beforeSettlementPayout
        );
      }),
      expectedSettlementAmount: computed(() => {
        let result = {
          KRW: null,
          USD: null,
        };

        if (state.existKRWExpectedSettlementAmount) {
          result.KRW = settlementPriceFormat(
            state.summary.expectedSettlementAmount["KRW"].currency,
            state.summary.expectedSettlementAmount["KRW"].amount
          );
        }
        if (state.existUSDExpectedSettlementAmount) {
          result.USD = settlementPriceFormat(
            state.summary.expectedSettlementAmount["USD"].currency,
            state.summary.expectedSettlementAmount["USD"].amount
          );
        }
        return result;
      }),
      existKRWExpectedSettlementAmount: computed(() => {
        return !!state.summary.expectedSettlementAmount["KRW"];
      }),
      existUSDExpectedSettlementAmount: computed(() => {
        return !!state.summary.expectedSettlementAmount["USD"];
      }),
      existBothCurrencySettlementAmount: computed(() => {
        return (
          state.existKRWExpectedSettlementAmount &&
          state.existUSDExpectedSettlementAmount
        );
      }),
      nonSettlementSalesAmount: computed(() => {
        let result = {
          KRW: null,
          USD: null,
        };

        if (state.existKRWNonSettlementSalesAmount) {
          result.KRW = settlementPriceFormat(
            state.summary.nonSettlementSalesAmount["KRW"].currency,
            state.summary.nonSettlementSalesAmount["KRW"].amount
          );
        }
        if (state.existUSDNonSettlementSalesAmount) {
          result.USD = settlementPriceFormat(
            state.summary.nonSettlementSalesAmount["USD"].currency,
            state.summary.nonSettlementSalesAmount["USD"].amount
          );
        }
        return result;
      }),
      nonSettlementKRWTotal: computed(() => {
        if (state.existKRWNonSettlementSalesAmount) {
          let price =
            state.summary.nonSettlementSalesAmount["KRW"].amount > 100000
              ? state.summary.nonSettlementSalesAmount["KRW"].amount +
                state.summary.totalPoint
              : state.summary.nonSettlementSalesAmount["KRW"].amount;
          if (state.summary.nonSettlementSalesAmount["KRW"].amount > 100000) {
            return settlementPriceFormat(
              state.summary.nonSettlementSalesAmount["KRW"].currency,
              price
            );
          } else {
            return settlementPriceFormat(
              state.summary.nonSettlementSalesAmount["KRW"].currency,
              price
            );
          }
        } else {
          return settlementPriceFormat("KRW", 0);
        }
      }),
      existUSDNonSettlementSalesAmount: computed(() => {
        return !!state.summary.nonSettlementSalesAmount["USD"];
      }),
      existKRWNonSettlementSalesAmount: computed(() => {
        return !!state.summary.nonSettlementSalesAmount["KRW"];
      }),
      existBothNonSettlementSalesAmount: computed(() => {
        return (
          state.existUSDNonSettlementSalesAmount &&
          state.existKRWNonSettlementSalesAmount
        );
      }),
      settleAble: computed(() => {
        const krwAmount = state.summary.expectedSettlementAmount["KRW"]
          ? state.summary.expectedSettlementAmount["KRW"].amount
          : 0;

        const usdAmount = state.summary.expectedSettlementAmount["USD"]
          ? state.summary.expectedSettlementAmount["USD"].amount
          : 0;

        const totalAmount = usdAmount * 1200 + krwAmount;

        return totalAmount > 100000; // 10만원 이상일 경우
      }),
      settleAbleTooltipText: computed(() => {
        return state.settleAble ? "예정" : "이월";
      }),
      settleAbleTooltipMessage: computed(() => {
        return state.settleAble
          ? "정산 예정 금액은 판매 금액에서<br/>각종 수수료를 뺀 정산 금액이에요"
          : "정산 예정 금액이 10만원 이하면<br/>정산 금액이 다음 달로 이월돼요";
      }),
      showBankModal: false,
      showCalculateModal: false,
      activeTab: computed(() => {
        let routeNameArr = route.name.split(".");
        return routeNameArr[routeNameArr.length - 1];
      }),
    });

    onMounted(() => {
      store.dispatch("settlements/getSettlementSummary");
    });

    const actions = {
      openBankBook: () => {
        state.showBankModal = true;
      },
      closeBankModal: () => {
        state.showBankModal = false;
      },
      openCalculateModal: () => {
        state.showCalculateModal = true;
      },
      closeCalculateModal: () => {
        state.showCalculateModal = false;
      },
      closeBankInfoDimmer: () => {
        bankDimmer.value.classList.add("insert-coin");
        setTimeout(() => {
          state.showBankInfo = true;
          setTimeout(() => {
            bankCard.value.classList.add("show-pig");
          }, 200);
        }, 900);
      },
      changeTab: (tabName) => {
        router.push({ name: `console.settlement.${tabName}` });
      },
    };

    return { state, actions, bankDimmer, bankCard };
  },
};
</script>

<style src="./style.css" scoped></style>
