<template>
  <div class="in-out-item">
    <div
      v-if="!state.isSettlementType"
      class="non-settlement__type"
      :class="state.itemClass"
    >
      <div class="icon-wrapper">
        <component
          :is="state.iconComponent"
          fill-color="#818287"
          :fill="true"
          width="24"
          height="24"
        ></component>
      </div>
      <div class="txt">
        <p class="tit sub-text-s2">{{ inOutItem.title }}</p>
        <p class="desc sub-text-s3 text-gray-second">{{ inOutItem.type }}</p>
      </div>
      <span class="price sub-title-s2">{{ state.price }}</span>
    </div>

    <button-accordion
      v-if="state.isSettlementType"
      @toggleContents="actions.toggleArrowDirection()"
    >
      <template #button>
        <div class="non-settlement__type" :class="state.itemClass">
          <div class="icon-wrapper">
            <component
              :is="state.iconComponent"
              fill-color="#818287"
              :fill="true"
              width="24"
              height="24"
            ></component>
          </div>
          <div class="txt">
            <p class="tit sub-text-s2">{{ inOutItem.title }}</p>
            <p class="desc sub-text-s3 text-gray-second">
              {{ inOutItem.type }}
            </p>
          </div>
          <span class="price sub-title-s2">
            {{ state.price }}
            <arrow-icon
              stroke-size="normal"
              :direction="state.showSettlementTypeDropdown ? 'up' : 'down'"
            ></arrow-icon>
          </span>
        </div>
      </template>
      <template #contents>
        <div class="settlement-card">
          <ul>
            <li>
              <span class="sub-text-s3 text-gray-second">정산 대상 금액</span>
              <span class="sub-title-s2">{{ state.amount }}</span>
            </li>
            <li>
              <span class="sub-text-s3 text-gray-second">공제 금액</span>
              <span class="sub-title-s2">{{ state.tax }}</span>
            </li>
            <li>
              <span class="sub-text-s3 text-gray-second">포인트 사용</span>
              <span class="sub-title-s2">{{ state.point }}</span>
            </li>
          </ul>
          <hr class="hr-third" />
          <div class="sum">
            <span class="sub-text-s3 text-gray-second">실수령 금액</span>
            <span class="sub-title-s2 text-blue-50">
              {{ state.realGetAmount }}
            </span>
          </div>
        </div>
      </template>
    </button-accordion>
  </div>
</template>

<script>
import { computed, normalizeClass, reactive } from "vue";
import settlementConst from "@/consts/settlement";
import WalletIcon from "../../../../components/console/icons/WalletIcon";
import PointIcon from "../../../../components/console/icons/PointIcon";
import TicketIcon from "../../../../components/console/icons/TicketIcon";
import { settlementPriceFormat } from "@/helper/settlement";
import ButtonDropdown from "@/pages/console/ContentShow/ButtonDropdown/ButtonDropdown.vue";
import ButtonAccordion from "@/components/console/buttons/ButtonAccordion.vue";
import ArrowIcon from "@/components/console/icons/ArrowIcon.vue";
import helper from "@/helper";

export default {
  name: "BankbookInOutItem",
  components: {
    ArrowIcon,
    ButtonAccordion,
    ButtonDropdown,
    PointIcon,
    WalletIcon,
    TicketIcon,
  },
  props: {
    inOutItem: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      itemClass: computed(() => {
        switch (props.inOutItem.type) {
          case settlementConst.bankbookListType.payment:
            return "income";
          case settlementConst.bankbookListType.point:
            if (props.inOutItem.amount < 0) {
              return "outcome";
            } else {
              return "point";
            }
          default:
            return "outcome";
        }
      }),
      iconComponent: computed(() => {
        switch (props.inOutItem.type) {
          case settlementConst.bankbookListType.payment:
            return "WalletIcon";
          case settlementConst.bankbookListType.point:
            return "PointIcon";
          default:
            return "TicketIcon";
        }
      }),
      isSettlementType: computed(() => {
        return (
          props.inOutItem.type === settlementConst.bankbookListType.settlement
        );
      }),
      amount: computed(() => {
        return settlementPriceFormat("KRW", props.inOutItem.amount);
      }),

      tax: computed(() => {
        return `-${settlementPriceFormat("KRW", props.inOutItem.tax)}`;
      }),
      point: computed(() => {
        return `${helper.priceFormat(props.inOutItem.usePoint)}P`;
      }),
      price: computed(() => {
        const currency = props.inOutItem.currency;

        switch (props.inOutItem.type) {
          case settlementConst.bankbookListType.payment:
            return `${settlementPriceFormat(currency, props.inOutItem.amount)}`;
          case settlementConst.bankbookListType.point:
            return `${props.inOutItem.amount}P`;
          default:
            return `-${settlementPriceFormat(
              currency,
              props.inOutItem.amount
            )}`;
        }
      }),
      realGetAmount: computed(() => {
        let resultValue =
          props.inOutItem.amount -
          props.inOutItem.tax +
          props.inOutItem.usePoint;
        return settlementPriceFormat("KRW", resultValue);
      }),
      showSettlementTypeDropdown: false,
    });

    const actions = {
      toggleArrowDirection: () => {
        state.showSettlementTypeDropdown = !state.showSettlementTypeDropdown;
      },
    };

    return { state, actions };
  },
  methods: { normalizeClass },
};
</script>

<style src="./style.css" scoped></style>
