<template>
  <icon-base
    icon-name="point-icon"
    v-bind:view-box="viewBox"
    v-bind:width="width"
    v-bind:height="height"
    v-bind:icon-color="iconColor"
  >
    <template v-if="!fill"> </template>
    <template v-if="fill">
      <g clip-path="url(#clip0_51_2623)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM7.18652 10.5H6.29785V5.55078H8.15723C9.29199 5.55078 9.89697 6.24121 9.90039 7.19141C9.89697 8.13818 9.28516 8.8252 8.14355 8.8252H7.18652V10.5ZM7.18652 6.2959V8.09375H8.02051C8.69043 8.09033 8.99121 7.71436 8.99121 7.19141C8.99121 6.66162 8.69043 6.2959 8.02051 6.2959H7.18652Z"
          v-bind:fill="fillColor"
          v-bind:stroke-width="state.strokeWidth"
        />
      </g>
      <defs>
        <clipPath id="clip0_51_2623">
          <rect
            width="75%"
            height="75%"
            v-bind:fill="fillColor"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </template>
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";
export default {
  name: "PointIcon",
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
    fill: {
      type: Boolean,
      default: false,
    },
  },
  components: { IconBase },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
