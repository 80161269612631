import helper from "@/helper";
import commonConst from "@/consts";

export function settlementPriceFormat(currency, price) {
  const currencyUnit =
    commonConst.currency[currency] === commonConst.currency.KRW ? "₩" : "$";
  return `${currencyUnit}${helper.priceFormat(price)}`;
}

export function pointFormat(point) {
  return `${helper.priceFormat(point)}P`;
}
