export default {
  corporationType: {
    personal: {
      text: "개인",
      value: 1,
    },
    personalCorp: {
      text: "개인사업자",
      value: 2,
    },
    corp: {
      text: "법인사업자",
      value: 3,
    },
  },

  bankList: [
    {
      value: 1,
      text: "국민은행",
    },
    {
      value: 2,
      text: "KEB 하나은행",
    },
    {
      value: 3,
      text: "우리은행",
    },
    {
      value: 4,
      text: "신한은행",
    },
    {
      value: 5,
      text: "기업은행",
    },
    {
      value: 6,
      text: "한국씨티은행",
    },
    {
      value: 7,
      text: "SC제일은행",
    },
    {
      value: 8,
      text: "경남은행",
    },
    {
      value: 9,
      text: "광주은행",
    },
    {
      value: 10,
      text: "대구은행",
    },
    {
      value: 11,
      text: "부산은행",
    },
    {
      value: 12,
      text: "전북은행",
    },
    {
      value: 13,
      text: "제주은행",
    },
    {
      value: 14,
      text: "새마을금고",
    },
    {
      value: 15,
      text: "우체국",
    },
    {
      value: 16,
      text: "농협은행",
    },
    {
      value: 17,
      text: "신협은행",
    },
    {
      value: 18,
      text: "산업은행",
    },
    {
      value: 19,
      text: "수협은행",
    },
    {
      value: 20,
      text: "카카오뱅크",
    },
    {
      value: 21,
      text: "토스뱅크",
    },
    {
      value: 22,
      text: "케이뱅크",
    },
  ],
  infoStatus: {
    wait: {
      value: "W",
      text: "정보 심사중",
    },
    fail: {
      value: "F",
      text: "심사반려",
    },
    confirm: {
      value: "C",
      text: "심사 승인",
    },
  },
  settlementStatus: {
    fail: {
      value: "F",
      text: "정산 실패",
    },
    confirm: {
      value: "C",
      text: "정산 완료",
    },
  },
  bankbookListType: {
    settlement: "정산",
    payment: "결제",
    point: "포인트",
  },
  // 대기:W, 입금대기:BW, 입금취소:BC, 완료:C, F: 실패, RA:환불, RP:부분환불, DA:전액할인, DP:부분할인
  saleStatus: {
    wait: {
      value: "W",
      text: "대기",
    },
    incomeWait: {
      value: "BW",
      text: "입금대기",
    },
    incomeCancel: {
      value: "BC",
      text: "입금취소",
    },
    complete: {
      value: "C",
      text: "구매 완료",
    },
    fail: {
      value: "F",
      text: "실패",
    },
    refund: {
      value: "RA",
      text: "환불",
    },
    refundPercent: {
      value: "RP",
      text: "부분환불", // note 가격일부 환불 후 티켓 권한을 뻇는다.
    },
    DCAll: {
      value: "DA",
      text: "전액할인",
    },
    DCPercent: {
      value: "DP",
      text: "부분할인", // note 가격일부 환불 후 티켓권한 그대로 유지
    },
  },

  pointType: {
    save: {
      value: 1,
      text: "적립",
    },
    use: {
      value: 6,
      text: "정산", // 사용
    },
    disappear: {
      value: 8,
      text: "소멸",
    },
  },
};
