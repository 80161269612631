<template>
  <base-modal-side
    :is-dimmed="true"
    :auto-hide="true"
    @closeModal="actions.closeModal()"
  >
    <template #modalHeader>
      <h3 class="modal-title">빅크 통장</h3>
    </template>

    <template #modalBody>
      <div class="current-cash">
        <span class="sub-text-s1 text-gray-reverse">현재 보유 금액</span>
        <div v-if="state.existKRWNonSettlementSalesAmount" class="summary">
          <div class="summary-amount__item">
            <span class="sub-text-s2 text-gray-reverse">원화</span>
            <h3 class="text-gray-reverse">
              {{ state.nonSettlementKRWTotal }}
            </h3>
          </div>
          <div class="summary-amount__item">
            <span class="sub-text-s3 text-gray-third">판매 금액(VAT 포함)</span>
            <span class="sub-title-s2 text-gray-third">
              {{ state.nonSettlementSalesAmount.KRW }}
            </span>
          </div>
          <div class="summary-amount__item">
            <span class="sub-text-s3 text-gray-third">판매 금액(VAT 포함)</span>
            <span class="sub-title-s2 text-gray-third">
              {{ state.totalPoint }}
            </span>
          </div>
        </div>

        <div v-if="state.existUSDNonSettlementSalesAmount" class="summary">
          <div class="summary-amount__item">
            <span class="sub-text-s2 text-gray-reverse">달러</span>
            <h3 class="text-gray-reverse">
              {{ state.nonSettlementSalesAmount.USD }}
            </h3>
          </div>
        </div>
      </div>

      <p class="c-text text-gray-second">
        * 제공된 빅크 포인트는 콘텐츠 판매 금액의 10%까지 정산 가능하며, 자동
        정산됩니다.
      </p>

      <hr class="hr-third" />

      <h5>통장 내역</h5>

      <ul v-if="state.showHistory" class="date-list">
        <li
          v-for="(bankbookDayHistory, index) in state.bankbookHistories"
          :key="`bankbook-day-${index}`"
        >
          <bankbook-date
            :bankbook-day-history="bankbookDayHistory"
          ></bankbook-date>
        </li>
      </ul>
    </template>

    <template #modalFooter>
      <button class="modal-footer-btn" @click="actions.closeModal()">
        확인
      </button>
    </template>
  </base-modal-side>
</template>
<script>
import BaseModalSide from "../../../../components/common/BaseModalSide";
import { computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import moment from "moment-timezone";
import BankbookDate from "../BankbookDate/BankbookDate";
import { settlementPriceFormat } from "@/helper/settlement";
import helper from "@/helper";

export default {
  name: "BankModal",
  components: {
    BankbookDate,

    BaseModalSide,
  },
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      summary: computed(() => {
        return store.getters["settlements/settlementSummary"];
      }),
      totalPoint: computed(() => {
        return `${helper.priceFormat(state.summary.totalPoint)}P`;
      }),
      showHistory: false,
      bankbookList: computed(() => {
        return store.getters["settlements/settlementBankbook"];
      }),
      bankbookHistories: computed(() => {
        if (state.bankbookList.length > 0) {
          let dateFormat = "YYYYMMDD";
          let prevItemDate = moment(state.bankbookList[0].createdAt).format(
            dateFormat
          );
          let i = 0;
          let j = 0;
          let result = [];
          let length = state.bankbookList.length;
          for (let k = 0; k < length; k++) {
            if (!result[i]) {
              result[i] = [];
            }
            let item = state.bankbookList[k];
            let currentItemDate = moment(item.createdAt).format(dateFormat);
            if (currentItemDate === prevItemDate) {
              result[i].push(item);
              j++;
            } else {
              i++;
              j = 0;
              prevItemDate = currentItemDate;
              if (!result[i]) {
                result[i] = [];
              }
              result[i][j] = item;
            }
          }

          return result;
        } else {
          return [];
        }
      }),
      nonSettlementSalesAmount: computed(() => {
        let result = {
          KRW: null,
          USD: null,
        };

        if (state.existKRWNonSettlementSalesAmount) {
          result.KRW = settlementPriceFormat(
            state.summary.nonSettlementSalesAmount["KRW"].currency,
            state.summary.nonSettlementSalesAmount["KRW"].amount
          );
        }
        if (state.existUSDNonSettlementSalesAmount) {
          result.USD = settlementPriceFormat(
            state.summary.nonSettlementSalesAmount["USD"].currency,
            state.summary.nonSettlementSalesAmount["USD"].amount
          );
        }
        return result;
      }),
      nonSettlementKRWTotal: computed(() => {
        if (state.existKRWNonSettlementSalesAmount) {
          let price =
            state.summary.nonSettlementSalesAmount["KRW"].amount > 100000
              ? state.summary.nonSettlementSalesAmount["KRW"].amount +
                state.summary.totalPoint
              : state.summary.nonSettlementSalesAmount["KRW"].amount;
          if (state.summary.nonSettlementSalesAmount["KRW"].amount > 100000) {
            return settlementPriceFormat(
              state.summary.nonSettlementSalesAmount["KRW"].currency,
              price
            );
          } else {
            return settlementPriceFormat(
              state.summary.nonSettlementSalesAmount["KRW"].currency,
              price
            );
          }
        } else {
          return settlementPriceFormat("KRW", 0);
        }
      }),
      existUSDNonSettlementSalesAmount: computed(() => {
        return !!state.summary.nonSettlementSalesAmount["USD"];
      }),
      existKRWNonSettlementSalesAmount: computed(() => {
        return !!state.summary.nonSettlementSalesAmount["KRW"];
      }),
      existBothNonSettlementSalesAmount: computed(() => {
        return (
          state.existUSDNonSettlementSalesAmount &&
          state.existKRWNonSettlementSalesAmount
        );
      }),
    });

    onMounted(() => {
      store.dispatch("settlements/getBankBookList").then(() => {
        state.showHistory = true;
      });
    });

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
