<template>
  <div class="accordion-wrapper" :class="{ reverse: reverse }">
    <div
      ref="accordionButton"
      class="accordion-btn"
      :class="{ active: !disabled }"
      @click="actions.toggleAccordion()"
    >
      <slot name="button"></slot>
    </div>

    <div ref="accordion" class="accordion">
      <slot name="contents"></slot>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, ref } from "vue";

export default {
  name: "ButtonAccordion",
  props: {
    autoHide: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    isFloating: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["toggleContents"],
  setup(props, { emit }) {
    const accordion = ref();
    const accordionButton = ref();

    const state = reactive({
      showAccordion: false,
    });

    onMounted(() => {
      if (props.isFloating) {
        accordion.value.style.position = "absolute";
        accordion.value.style.width = "100%";
        accordion.value.style.height = `${accordion.value.scrollHeight + 1}px`;
      }
    });

    const autoCloseModal = ($event) => {
      if (
        !accordionButton.value.contains($event.target) &&
        !accordion.value.contains($event.target) &&
        props.autoHide
      ) {
        toggleAccordion();
      }
    };

    const toggleAccordion = () => {
      emit("toggleContents");
      state.showAccordion = !state.showAccordion;
      if (!state.showAccordion) {
        accordion.value.style.maxHeight = null;
        window.removeEventListener("click", autoCloseModal);
      } else {
        accordion.value.style.maxHeight = `${
          accordion.value.scrollHeight + 1
        }px`;

        if (props.isFloating) {
          accordion.value.style.position = "absolute";
          accordion.value.style.width = "100%";
          accordion.value.style.height = `${
            accordion.value.scrollHeight + 1
          }px`;
        }
        window.addEventListener("click", autoCloseModal);
      }
    };

    const closeAccordion = () => {
      state.showAccordion = false;
      accordion.value.style.maxHeight = null;
      if (props.autoHide) {
        window.removeEventListener("click", autoCloseModal);
      }
    };

    const openAccordion = () => {
      state.showAccordion = true;
      accordion.value.style.maxHeight = `${accordion.value.scrollHeight + 1}px`;
      emit("toggleContents");
      if (props.autoHide) {
        window.addEventListener("click", autoCloseModal);
      }
    };

    const actions = {
      toggleAccordion: () => {
        if (!props.disabled) {
          toggleAccordion();
        }
      },
    };

    return {
      state,
      actions,
      accordion,
      accordionButton,
      toggleAccordion,
      closeAccordion,
      openAccordion,
    };
  },
};
</script>

<style scoped>
.accordion-wrapper {
  position: relative;
}

.accordion-btn.active {
  cursor: pointer;
}

.accordion {
  position: relative;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  z-index: 10;
}

.reverse {
  display: flex;
  flex-direction: column-reverse;
}

.enter-club-show .accordion {
  transition-duration: 0.7s;
}
</style>
