import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8568bc34"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_help_icon = _resolveComponent("help-icon")!
  const _component_tooltip = _resolveComponent("tooltip")!

  return (_openBlock(), _createBlock(_component_tooltip, {
    class: "help-tooltip",
    triggers: ['hover'],
    placement: $props.placement,
    container: $props.container
  }, {
    button: _withCtx(() => [
      _createVNode(_component_help_icon, { "fill-color": $props.iconColor }, null, 8, ["fill-color"])
    ]),
    tooltip: _withCtx(() => [
      _createElementVNode("p", {
        class: "tooltip-content c-text text-gray-second",
        innerHTML: $props.tooltipText
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["placement", "container"]))
}